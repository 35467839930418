import LayoutConfig from "./LayoutConfigTypes";

const DefaultThemeConfig: LayoutConfig = {
  name: "Start",
  version: "v1.0.3",
  fullName: "Caldeira Cloud",
  loader: {
    display: true,
    type: "spinner-logo",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    width: "fixed",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  megaMenu: {
    display: true,
  },
  aside: {
    display: true,
    fixed: true,
    menu: "main",
    primaryDisplay: true,
    secondaryDisplay: true,
    toggle: true,
    minimized: false,
  },
  content: {
    width: "fixed",
  },
  toolbar: {
    display: true,
    width: "fixed",
    breadcrumb: true,
  },
  sidebar: {
    display: true,
    toggle: false,
    shown: true,
    content: "general",
    bgColor: "bg-info",
    displayFooter: true,
    displayFooterButton: true,
  },
  footer: {
    width: "fixed",
  },
};

export default DefaultThemeConfig;
