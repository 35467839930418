
import { defineComponent, onMounted, ref } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { ScrollComponent } from "@/assets/ts/components";

export default defineComponent({
  name: "Sidebar3",
  components: {
    Dropdown3,
  },
  setup() {
    const list = ref([
      {
        url: "",
        title: "Blue Donut",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-17.jpg",
      },
      {
        url: "",
        title: "Lovely Hearts",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-10.jpg",
      },
      {
        url: "",
        title: "Hands & Yellow",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-1.jpg",
      },
      {
        url: "",
        title: "Cup & Green",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-9.jpg",
      },
      {
        url: "",
        title: "Bose QC 35 II",
        desc: "Study the highway types",
        image: "media/stock/600x400/img-4.jpg",
      },
    ]);
    const series = ref([100]);
    const chartOptions = ref({
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "99%",
            background: "#FFF",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#dadce0",
            strokeWidth: "0%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#FFF"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
      colors: ["#dadce0"],
    });

    return {
      list,
      series,
      chartOptions,
    };
  },
});
