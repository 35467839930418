
import { defineComponent, onMounted, ref } from "vue";
import useShopFilter from "@/core/helpers/shop";

export default defineComponent({
  name: "Sidebar2",
  components: {},
  setup() {
    const {
      displayFilterForm,
      showProductAddForm,
      showProductFilterForm,
      initSlider,
      newProductFormSubmit,
    } = useShopFilter();

    const sliderRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      if (sliderRef.value) {
        sliderRef.value.innerHTML = "";
      }

      initSlider();
    });

    const getCategories = ref([
      {
        value: "tshirts",
        label: "Camisetas",
      },
      {
        value: "tshirts",
        label: "Canecas",
      },
      {
        value: "tshirts",
        label: "Toalhas",
      },
    ]);

    const getSizes = ref([
      {
        value: "XS",
        label: "XS",
      },
      {
        value: "S",
        label: "S",
      },
      {
        value: "M",
        label: "M",
      },
      {
        value: "L",
        label: "L",
      },
      {
        value: "XL",
        label: "XL",
      },
    ]);

    return {
      displayFilterForm,
      showProductAddForm,
      showProductFilterForm,
      newProductFormSubmit,
      getCategories,
      getSizes,
    };
  },
});
