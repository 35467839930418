/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPageTable = /* GraphQL */ `
  query GetPageTable($id: ID!) {
    getPageTable(id: $id) {
      id
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      title
      shortDescription
      description
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      metadata
      description1
      description2
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPageTables = /* GraphQL */ `
  query ListPageTables(
    $filter: ModelPageTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPageTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        table
        pageId
        microservice
        resource
        pageBreadcrumbs
        title
        shortDescription
        description
        search
        searchPlaceholder
        banner
        bannerSmall
        widgetId
        newsId
        breadcrumbsId
        featureCollectionId
        enabled
        menu
        metadata
        description1
        description2
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItemFeatureTable = /* GraphQL */ `
  query GetItemFeatureTable($id: ID!) {
    getItemFeatureTable(id: $id) {
      id
      table
      itemFeatureId
      microservice
      resourceFrom
      pageBreadcrumbs
      title
      shortDescription
      description
      banner
      bannerSmall
      widgetId
      newsId
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listItemFeatureTables = /* GraphQL */ `
  query ListItemFeatureTables(
    $filter: ModelItemFeatureTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemFeatureTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        table
        itemFeatureId
        microservice
        resourceFrom
        pageBreadcrumbs
        title
        shortDescription
        description
        banner
        bannerSmall
        widgetId
        newsId
        metadata
        enabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEnterpriseFeature = /* GraphQL */ `
  query GetEnterpriseFeature($id: ID!) {
    getEnterpriseFeature(id: $id) {
      id
      slugname
      avatar
      name
      legalregister
      verified
      geolocation
      url
      metadata
      enabled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEnterpriseFeatures = /* GraphQL */ `
  query ListEnterpriseFeatures(
    $filter: ModelEnterpriseFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnterpriseFeatures(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slugname
        avatar
        name
        legalregister
        verified
        geolocation
        url
        metadata
        enabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfileFeature = /* GraphQL */ `
  query GetProfileFeature($id: ID!) {
    getProfileFeature(id: $id) {
      id
      username
      avatar
      name
      birthday
      age
      gender
      geolocation
      verified
      metadata
      enabled
      auth_signup_id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProfileFeatures = /* GraphQL */ `
  query ListProfileFeatures(
    $filter: ModelProfileFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfileFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        avatar
        name
        birthday
        age
        gender
        geolocation
        verified
        metadata
        enabled
        auth_signup_id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureCollectionTable = /* GraphQL */ `
  query GetFeatureCollectionTable($id: ID!) {
    getFeatureCollectionTable(id: $id) {
      id
      name
      type
      label
      title
      description
      featureFilters
      features
      pageId
      createdAt
      updatedAt
      metadata
      table
      featureCollectionId
      microservice
      resourceFrom
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      itemFeatureCollection
      enabled
      __typename
    }
  }
`;
export const listFeatureCollectionTables = /* GraphQL */ `
  query ListFeatureCollectionTables(
    $filter: ModelFeatureCollectionTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatureCollectionTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        label
        title
        description
        featureFilters
        features
        pageId
        createdAt
        updatedAt
        metadata
        table
        featureCollectionId
        microservice
        resourceFrom
        pageBreadcrumbs
        shortDescription
        search
        searchPlaceholder
        banner
        bannerSmall
        widgetId
        newsId
        breadcrumbsId
        itemFeatureCollection
        enabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPageFeatureTable = /* GraphQL */ `
  query GetPageFeatureTable($id: ID!) {
    getPageFeatureTable(id: $id) {
      id
      url
      title
      description
      type
      createdAt
      updatedAt
      metadata
      table
      pageId
      microservice
      resource
      pageBreadcrumbs
      shortDescription
      search
      searchPlaceholder
      banner
      bannerSmall
      widgetId
      newsId
      breadcrumbsId
      featureCollectionId
      enabled
      menu
      __typename
    }
  }
`;
export const listPageFeatureTables = /* GraphQL */ `
  query ListPageFeatureTables(
    $filter: ModelPageFeatureTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPageFeatureTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        title
        description
        type
        createdAt
        updatedAt
        metadata
        table
        pageId
        microservice
        resource
        pageBreadcrumbs
        shortDescription
        search
        searchPlaceholder
        banner
        bannerSmall
        widgetId
        newsId
        breadcrumbsId
        featureCollectionId
        enabled
        menu
        __typename
      }
      nextToken
      __typename
    }
  }
`;
