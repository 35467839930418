import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container d-flex align-items-center justify-content-center" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "row mx-2"
}
const _hoisted_5 = { class: "col px-10 pt-5" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  class: "sidebar-nav nav nav-tabs pt-8 pb-5 px-5",
  id: "kt_sidebar_tabs",
  role: "tablist"
}
const _hoisted_9 = ["href", "id", "onClick"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  id: "kt_sidebar_content",
  class: "pt-5 pb-10 px-5 px-lg-5"
}
const _hoisted_13 = {
  class: "hover-scroll-y me-lg-n2 pe-lg-2",
  "data-kt-scroll": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0px",
  "data-kt-scroll-dependencies": "#kt_sidebar_tabs, #kt_sidebar_footer",
  "data-kt-scroll-wrappers": "#kt_sidebar_content"
}
const _hoisted_14 = { class: "tab-content" }
const _hoisted_15 = ["id"]
const _hoisted_16 = { class: "card bg-transparent" }
const _hoisted_17 = { class: "card-header align-items-center border-0" }
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "card-toolbar" }
const _hoisted_20 = {
  type: "button",
  class: "btn btn-md btn-icon btn-icon-white btn-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-overflow": "true",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_21 = { class: "svg-icon svg-icon-1" }
const _hoisted_22 = { class: "card-body" }
const _hoisted_23 = { class: "symbol symbol-40px symbol-2by3 me-4" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "d-flex flex-column flex-grow-1 my-lg-0 my-8 pe-3" }
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = {
  id: "kt_sidebar_footer",
  class: "py-2 px-5 pb-md-6 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: _ctx.ecommerceRecomendationCollection.logo,
              class: "h-90px mt-8",
              alt: "Logo"
            }, null, 8, _hoisted_3)
          ]),
          _: 1
        })
      ])
    ]),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h4", {
              class: "fw-bolder text-dark",
              innerHTML: _ctx.ecommerceRecomendationCollection.title
            }, null, 8, _hoisted_6),
            _createElementVNode("div", {
              class: "fs-7",
              style: {"word-wrap":"break-word"},
              innerHTML: _ctx.ecommerceRecomendationCollection.subtitle
            }, null, 8, _hoisted_7)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ecommerceRecomendationCollection.featureCollection, (nav) => {
        return (_openBlock(), _createElementBlock("li", {
          key: nav.id,
          class: "nav-item"
        }, [
          _createElementVNode("a", {
            class: _normalizeClass(["nav-link bg-white", {
          active: _ctx.currentActive == nav.id,
        }]),
            "data-bs-toggle": "tab",
            href: `#kt_sidebar_tab_pane_${nav.id}`,
            id: `kt_sidebar_tab_${nav.id}`,
            onClick: ($event: any) => (_ctx.setActive(nav.id))
          }, [
            (_ctx.currentActive == nav.id)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  alt: "",
                  src: nav.iconActive,
                  class: "active"
                }, null, 8, _hoisted_10))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  alt: "",
                  src: nav.icon,
                  class: "default"
                }, null, 8, _hoisted_11))
          ], 10, _hoisted_9)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ecommerceRecomendationCollection.featureCollection, (nav) => {
            return (_openBlock(), _createElementBlock("div", {
              key: nav.id,
              class: _normalizeClass(["tab-pane", {
            active: _ctx.currentActive == nav.id,
          }]),
              id: `kt_sidebar_tab_pane_${nav.id}`,
              role: "tabpanel"
            }, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("h3", {
                    class: "card-title fw-bolder text-dark fs-3",
                    innerHTML: nav.title
                  }, null, 8, _hoisted_18),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("button", _hoisted_20, [
                      _createElementVNode("span", _hoisted_21, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
                      ])
                    ]),
                    _createVNode(_component_Dropdown3)
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nav.items, (item) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: item.id,
                      class: "d-flex flex-wrap align-items-center mb-7"
                    }, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("img", {
                          src: item.metadata.images[0].href,
                          alt: "",
                          class: "w-300px h-350px"
                        }, null, 8, _hoisted_24)
                      ]),
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("span", {
                          class: "text-dark fw-bold fs-6 my-1",
                          innerHTML: item.shortDescription
                        }, null, 8, _hoisted_26),
                        _createVNode(_component_router_link, {
                          to: item.metadata.href,
                          class: "text-dark fw-bolder text-hover-primary fs-6",
                          innerHTML: item.metadata.hrefTitle
                        }, null, 8, ["to", "innerHTML"])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ], 10, _hoisted_15))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createVNode(_component_router_link, {
        to: _ctx.ecommerceRecomendationCollection.href,
        class: "btn btn-color-white bg-primary bg-hover-opacity-20 fw-bolder w-100",
        innerHTML: _ctx.ecommerceRecomendationCollection.callToAction
      }, null, 8, ["to", "innerHTML"])
    ])
  ], 64))
}