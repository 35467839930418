
import { defineComponent, onMounted } from "vue";
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { ScrollComponent } from "@/assets/ts/components";
import ecommerceRecomendationData from "@/data/ecommerce-recommendation-collection.json";

export default defineComponent({
  name: "Sidebar",
  components: {
    Dropdown3,
  },
  setup() {
    onMounted(() => {
      ScrollComponent.createInstances('[data-kt-scroll="true"]');
    });

    const ecommerceRecomendationCollection = ecommerceRecomendationData;

    return {
      ecommerceRecomendationCollection,
    };
  },
  data() {
    return {
      currentActive: 1,
    };
  },
  methods: {
    setActive(id) {
      this.currentActive = id;
    },
  },
});
