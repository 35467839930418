
import { defineComponent, onMounted, ref } from "vue";
import useShopFilter from "@/core/helpers/shop";

import { API, Storage, graphqlOperation } from "aws-amplify";
import { listPageFeatureTables } from "@/graphql/queries";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Sidebar4",
  components: {},
  setup() {
    const {
      displayFilterForm,
      showProductAddForm,
      showProductFilterForm,
      initSlider,
      newProductFormSubmit,
    } = useShopFilter();

    const sliderRef = ref<HTMLElement | null>(null);

    onMounted(() => {
      if (sliderRef.value) {
        sliderRef.value.innerHTML = "";
      }

      initSlider();
    });

    return {
      displayFilterForm,
      showProductAddForm,
      showProductFilterForm,
      newProductFormSubmit,
    };
  },

  mounted() {
    this.loadPage(
      "caldeiracloud_" + this.$route.params.slugname + "_pagefeature"
    );
  },
  data() {
    return {
      page: {
        title: "",
        banner: "",
        metadata: {
          fields: [],
        },
      },
    };
  },
  methods: {
    async loadPage(pageFeatureFeedId) {
      try {
        const response: any = await API.graphql(
          graphqlOperation(listPageFeatureTables, {
            filter: {
              pageId: {
                eq: pageFeatureFeedId,
              },
              enabled: {
                eq: true,
              },
            },
          })
        );

        if (response.data.listPageFeatureTables.items.length > 0) {
          this.page = response.data.listPageFeatureTables.items[0];
          if (this.page) {
            let metadata = JSON.parse(
              response.data.listPageFeatureTables.items[0].metadata
            );
            this.page.metadata = metadata;

            const banner = await Storage.get("ai-tools/" + this.page.banner, {
              level: "public",
            });
            this.page.banner = banner;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
