/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://iffi3be75zdbzjwfapnorkcpji.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-645hcsfkmfghnklgw5tmpmlxau",
    "aws_cognito_identity_pool_id": "us-east-1:e47dfa98-11d0-40af-b0ac-4041e5631c8d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jmw1CCiGa",
    "aws_user_pools_web_client_id": "74gnrt3unujf7a22isno1cm2ff",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "PHONE_NUMBER",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "caldeiracloud-storage165620-dev165620-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
