import { createApp } from "vue";
import App from "@/App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/index";
import store from "@/store/index";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import BootstrapVue3 from "bootstrap-vue-3";

//imports for app initialization
import MockService from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";
import LayoutService from "@/core/services/LayoutService";
// import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { createGlobalComponents } from "@/core/components/components";

import VueAgile from "vue-agile"

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";

import Amplify from "aws-amplify";
import Auth from "@aws-amplify/auth";
import awsconfig from "./aws-exports";

import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-vue";
// import Analytics from "analytics";
// import segmentPlugin from "@analytics/segment";

import AWS from "aws-sdk";
AWS.config.update(awsconfig);

I18n.putVocabularies(translations);
I18n.setLanguage("pt");

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const app = createApp(App);

// const analytics = Analytics({
//     app: "poa.city",
//     plugins: [
//         segmentPlugin({
//             writeKey: "gPEesIf7hGF37ydpE5021H5xpGgbf2lP",
//         }),
//     ]
// });

// Definir a propriedade 'analytics' no objeto 'window' ou 'globalThis'
// global.window.window.analytics = analytics;

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(BootstrapVue3);
app.use(VueAgile)

// import VueApexCharts from "vue3-apexcharts";
// app.use(VueApexCharts)

createGlobalComponents(app);
// initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

ApiService.init(app);
LayoutService.init();
MockService.init(app);

app.mount("#app");
